.containerAdminDocs{
	padding-top: 5%;
	padding-bottom: 5%;
}

.ql-editor{
	color: #000 !important;
}

.margenBotonTextCustom{
	margin: 10px;
}

.inputImagenes{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 200px;
	height: 200px;
	padding: 5px;
	background: url(https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2FdocumentosAdmin%2Fdoc.png?alt=media&token=00577dfc-7d7c-4883-9aa0-38efdb112fd1);
	flex-direction: column;
    margin: auto;
    background-size: cover;
}

.elInputImagen{
	display: none;
}

.labelElInputImagen{
	width: 100px;
	padding: 10px;
	text-align: center;
	color: #fff;
	background: #00000099;
	border-radius: 20px;
	cursor: pointer;
}

.modalCambioEstado{
	text-align: center;
	padding: 10px;
}