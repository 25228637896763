.seccion1HomeAdmin{
	padding-top: 5%;
}

.letraBotonWarning{
	color: #fff;
}

.margen{
	margin: 10px;
}

.ocultar{
	display: none;
}

.tituloPaginaAdmin{
	color: #00003c;
	font-weight: bold;
}