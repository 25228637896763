.containerFormCampos{
	background: #191947;
	border-radius: 10px;
	padding: 10px;
}

.labelColorBlanco{
	color: #fff;
}

.containerOpciones{
	background: #f8c43c;
	border-radius: 10px;
	padding: 10px;
}

.opcion{
	background: #191947;
	border-radius: 10px;
	padding: 5px;
	display: inline-block;
	margin: 10px;
	color: #fff;
}

.equis{
	color: red;
	cursor: pointer;
}