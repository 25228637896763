.listaUsuarios{
	width: 100%;
	padding: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	border: 1px solid #000;
	border-radius: 10px;
}

.usuario{
	margin-top: 20px;
	padding: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.usuarios{
	width: 500px;
	padding: 20px;
}

.listaUsuarios{
	display: flex;
	flex-direction: row;
}

@media(max-width: 998px){
	.usuarios{
		width: 320px;
	}
}