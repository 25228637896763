.divCliente{
	width: 100%;
    min-height: 62vh;
    margin-top: 71px;
}
.editarCliente{
	color: #000;
	font-weight: bold;
	cursor: pointer;
}
.ocutarHomeCliente{
	display: none;
}
.tituloCambioEmail{
	font-weight: 800;
    line-height: 1em;
    color: #00003c;
    text-align: center;
    font-family: 'AvenirBlack';
}
.paddingCliente{
	padding: 20px;
}
