body{
	background: #fff;
}

.containerDocs{
	width: 100%;
	height: 100vh;
}

.seccion1Docs{
	background: #f5f5fc;
	width: 100%;
	/*border-bottom-right-radius: 40%;
	border-bottom-left-radius: 40%;*/
	text-align: center; 
	padding-top: 5%;
	padding-bottom: 2.5%;
	margin-top: 71px;
	/*min-height: 65vh;*/
}
.shape{
	fill: #fff;
    border-bottom: 0px solid #fff;
}
.container-shape{
	bottom: -8%;
    z-index: 1;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
}
.cls-1p{fill:#388fed;}
.cls-1t{fill:#eaeaf5;}
.figure1doc{
	background: url('https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fdocumentos%2Felement-grid-blue.svg?alt=media&token=418fe5a7-e4dc-4f86-b41e-1f0fe862f5b8&_gl=1*1e78uz4*_ga*MTMxNDUzMjAzMy4xNjgzNzI4Njg5*_ga_CW55HF8NVT*MTY4NTQ5MTQwMi4xNi4xLjE2ODU0OTE1MDkuMC4wLjA.');
	width: 10vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: unset;
    position: absolute;
    right: 20vw;
    top: 40px;
    z-index: 0;
}
.figure2doc{
	background: url('https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fdocumentos%2Felement-symbol-gris-2.svg?alt=media&token=534a6bfb-c1f5-46df-aaf5-1c324260e7c9&_gl=1*1l4ytjo*_ga*MTMxNDUzMjAzMy4xNjgzNzI4Njg5*_ga_CW55HF8NVT*MTY4NTQ5MTQwMi4xNi4xLjE2ODU0OTE1NjUuMC4wLjA.');
    width: 43vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: unset;
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 0;
}
.figure3doc{
	background: url('https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fdocumentos%2Felement-grid-magenta.svg?alt=media&token=74d4404d-742d-4f53-a80d-2f816df64c45&_gl=1*1hxnyi7*_ga*MTMxNDUzMjAzMy4xNjgzNzI4Njg5*_ga_CW55HF8NVT*MTY4NTQ5MTQwMi4xNi4xLjE2ODU0OTI3NzcuMC4wLjA.');
    width: 10vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    position: absolute;
    left: 10vw;
    bottom: -3vw;
    z-index: 1;
}
.extcomofunciona{
	    z-index: 2;
    position: relative;
}
.backgroundGrayTop{
	width: 100%;
    height: 90%;
    /*background: #bcbec9;*/
    padding: 0px;
    margin: 0px;
    position: absolute;
    z-index: 1;
    top: 240px;
    padding-top: 5rem;
}
.imgbackgroundGray{
	background-image: url(https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fdocumentos%2Felement-symbol-gris-3.svg?alt=media&token=c530987e-c9ef-4368-aa9c-b46d19114136&_gl=1*aom9k*_ga*MTMxNDUzMjAzMy4xNjgzNzI4Njg5*_ga_CW55HF8NVT*MTY4NTQ5NTIwNi4xNy4xLjE2ODU0OTUyODkuMC4wLjA.);
    background-repeat: no-repeat;
    background-position: left;
    width: 100%;
    height: calc(55rem * 0.5625);
    display: none;
}
.backgroundGray{
	width: 100%;
    height: 100%;
    /*background: #bcbec9;*/
    background: #fff;
    padding: 0px;
    margin: 0px;
    position: absolute;
    z-index: 0;
    top: -25%;
    border-bottom-left-radius: 25%;
    border-bottom-right-radius: 25%;
}
.seccion1ElDoc{
	background: url(https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fdocumentos%2FfondoDoc.png?alt=media&token=742efb20-1e83-45dd-ab7d-f5ca16d8f032);
	background-size: cover;
	background-position: center;
	width: 100%;
	/*border-bottom-right-radius: 15%;
	border-bottom-left-radius: 15%;*/
	margin-top: 71px;
	text-align: center; 
	min-height: 550px;
}
.seccion4Docs, .seccion3Docs{

    background: #f5f5fc;
}
.seccion2ElDoc{
	width: 100%;
	padding-top: 5%;
    padding-bottom: 5%;
}

.transparenciaAzul{
	background: rgba(1, 2, 54, 0.8);
	width: 100%;
	height: 100%;
	/*border-bottom-right-radius: 15%;
	border-bottom-left-radius: 15%;
	padding-top: 7.5%;
    padding-bottom: 7.5%;*/
    margin-top: 71px;
    min-height: 550px;
}

.infoCategoria{
	color: #6f74d4;
	font-size: 16px;
	font-family: 'AvenirRegular';
	font-weight: bold;
}

.tituloSeccion1ElDoc{
	color: #fff;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2em;
    font-family: 'AvenirBlack';
    /* width: 44%; */
    /*margin: auto;*/
    margin-top: 20px !important;
    text-align: center;
}

.descripcionDoc{
	color: #fff;
    font-size: 16px;
    font-family: 'AvenirRegular';
    text-align: left;
    margin: auto;
    width: 44%;
}

.tituloSeccionDocs{
	color: #00003c;
    font-size: 60px;
    font-weight: 800;
    line-height: 1.2em;
    display: inline-block;
    font-family: "AvenirBlack";
}

.icoDocs{
	display: inline-block;
	margin-top: -25px;
}

.parrafoDocs{
    color: #00003c;
    font-size: 1.2rem;
    font-weight: 400;
    font-family: 'AvenirRegular';
    width: 40rem;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 40px;
}

.container_search {
    display: flex;
    min-height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    overflow: hidden;
    border: 0 solid transparent;
    min-height: 50px;
    border: 0px;
    color: #e1195d;
    font-size: 18px;
    font-weight: 400;
    font-family: 'AvenirRegular';
    line-height: 1em;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #e1195d;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: auto;
    padding: 18px 18px 18px 18px;
    border-radius: 50px 50px 50px 50px !important;
    width: 45%;
    background-color: #fff;
    height: 60px;
}

.inputBuscar {
    border: 0;
    width: 95%; 
}

.inputBuscar::placeholder {
    font-size: 16px; 
}

.inputBuscar:focus{
	outline: none;
}

.infoCantidadDocs{
	color: #828399;
	margin-top: 20px;
	font-family: 'AvenirRegular';
}

.cantidadDocs{
	/*color: #e1195d;*/
	font-family: 'AvenirRegular';
	font-weight: bold;
}

.categorias{
	text-align: center;
	padding: 20px;
	    font-family: 'AvenirBlack';
    z-index: 12;
    position: relative;
}

.categoria{
	color: #828399;
	cursor: pointer;
	margin: 10px;
	font-size: 24px;
	font-weight: bold;
	font-family: 'AvenirRegular';
} 

.activa{
	color: #00003c !important;
}

.flechaAbajo{
	display: block;
	margin: auto;
}

.documento{
    background: white;
    height: 448px;
    margin: 1.5rem;
    border-radius: 50px;
    display: inline-block;
    width: 40%;
    box-shadow: 0px 0px 28px #cccc;
    z-index: 2;
    position: relative;
}

.tituloDoc{
	width: 100%;
    height: 120px;
    background: #00003c;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    display: flex;
    flex-direction: row;
	justify-content: center;
}

.tituloDocH4{
	color: #fff;
	/*font-weight: bold;*/
	font-family: 'AvenirBlack';
	width: 100%;
	text-align: center;
	margin: auto;
	font-size: 28px;
}

.infoDoc{
	width: 100%;
    height: 300px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
	text-align: center;
	padding: 30px;
}

.descripcionCorta{
	color: #000;
	width: 80%;
	font-family: 'AvenirRegular';
	font-size: 18px;
}

.precio{
	color: #00003c;
	font-weight: bold;
	font-family: 'AvenirBlack';
	font-size: 28px;
}

.colorRosa{
	background: #e1195d;
	border-color: #e1195d;
	font-family: 'AvenirRegular';
	border-radius: 7.5px;
	padding-left: 30px;
    padding-right: 30px;
    font-weight: bold;
}

.comprarDoc{
	background: #fff;
	border-radius: 50px;
	/*width: 320px;*/
	width: 380px;	
	/*padding: 10px;*/
	padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 25px;
    padding-right: 25px;
	text-align: center;
}

.comprarDocNombreDoc{
	color: #00003c;
	font-weight: bold;
	font-family: 'AvenirRegular';
}

.precioDoc{
	font-family: 'AvenirRegular';
	font-size: 24px;
	font-weight: bold;
	color: #e1195d;
}

.ivaYTiempo{
	font-family: 'AvenirRegular';
	color: #555555;
	font-size: 14px;
}

.colorPurpura{
	background: #7f86ef;
	border-color: #7f86ef;
	border-radius: 10px;
}

.elDocTituloSeccion2{
	color: #e1195d;
	font-weight: bold;
	font-family: 'AvenirRegular';
	font-size: 30px;
}

.u{
	margin-top: -60px;
}
.ue{
	/* margin-top: -60px; */
    position: absolute;
    top: 27%;
    width: 120%;
    z-index: 0;
    margin-left: -250px;
}

.listaDocumentos{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.seccion2DocsNuevosStyles{
	margin-top: -120px;
    /* padding-top: 150px; */
    background: #fff;
}

.paraQueSirve{
	width: 50%;
}

@media(min-width:1650px){
	.ue{
		/*margin-top: -60px; */
	    position: absolute;
	    top: 27%;
	    right: -20%;
	    width: 140%;
	    z-index: 0;
	}
	.hombreEmpresario{
		    width: 100%;
	    /* position: absolute; */
	    /* margin-left: -572px; */
	    z-index: 1;
	    position: inherit;
	    right: -25%;
	}
}

.paddingBottom{
	padding-bottom: 5%;
}

@media(max-width: 998px){
	.seccion1Docs{
		border-bottom-right-radius: 0%;
		border-bottom-left-radius: 0%; 
	}
	.icoDocs {
	    margin-top: -10px;
	    width: 60px;
	}
	.tituloSeccionDocs {
	    font-size: 28px;
	}
	.container_search{
		width: 100%;
	}
	.parrfoDocs{
		font-size: 16px;
	}
	.seccion1ElDoc {
	    border-bottom-right-radius: 0%;
	    border-bottom-left-radius: 0%;
	}
	.transparenciaAzul {
	    border-bottom-right-radius: 0%;
	    border-bottom-left-radius: 0%;
	}
	.tituloSeccion1ElDoc {
	    font-size: 28px;
	    text-align: center;
	    width: auto;
	}
	.descripcionDoc {
	    text-align: center;
	   	width: auto;
	}
	.comprarDoc {
	    width: auto;
	}
	.parrafoDocs{
		width: 100%;
	}
	.documento{
		margin: auto;
		margin-top: 20px;
		width: 100%;
	}
	.ue{
		display: none;
	}
	.seccion2DocsNuevosStyles{
		margin-top: auto;
	}
	.paraQueSirve{
		width: 100%;
	}
	.tituloDocH4{
		font-size: 20px;
	}
	.precio{
		font-size: 16px;
	}
	.descripcionCorta{
		font-size: 16px;
	}
}
