body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*/// servicios-styles ///*/
.containerAdminDocs{
	color: #000;
}
.margenServiciosLeft{
	margin-left: 5px;
}
.diasSemana{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.inputWidthCustom4 {
    width: 90%;
}
.formDia{
	margin: 10px;
}
/*/// servicios-styles ///*/