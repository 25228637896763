.seccion1Carrito{
	padding-top: 5%;
	padding-bottom: 5%;
	margin-top: 71px;
}

.tituloCarrito{
	width: 100%;
	text-align: center;
	color: #00003c;
	font-family: 'AvenirBlack';
	font-weight: bold;
}

.infoEstado{
	color: #bcbec9;
	font-size: 16px;
	font-family: 'AvenirRegular';
	font-weight: bold;
	width: 100%;
	text-align: center;
}

.estadoActivo{
	color: #e2045b;
}

.hrVertical{
	background: #cfd0d8;
	width: 1px;
	height: 100%;
	display: inline-block;
}

.hrHorizontal{
	background: #cfd0d8;
	width: 100%;
	height: 1px;
	display: inline-block;
}

.tituloIndicacion{
	color: #222222;
    font-family: 'AvenirBlack';
    font-weight: bold;
    display: block;
    margin: auto;
    width: 320px;
}

.flex{
	display: flex;
}

.formPedido{
	display: inline-block;
	padding: 10px;
}

.nombreDeProductoCarrito{
	color: #222222;
    font-family: 'AvenirRegular';
}

.botonDescuento{
	background: #bcbec9 !important;
	border: 1px solid #bcbec9;
}

.divPrecios{
	width: 320px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.infoPrecio{
	color: #222222;
    font-family: 'AvenirRegular';
}

.bold{
	font-weight: bold;
}

.precios{
	text-align: right;
	color: #222222;
    font-family: 'AvenirRegular';
}

.textPreciosIVA{
	color: #222222;
    font-family: 'AvenirRegular';
    font-size: 12px;
    display: block;
    margin: auto;
    text-align: center;
}

.divPagoSeguro{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.textImgPagoSeguro{
	display: flex;
}

.imgPagoSeguro{
	display: inline-block;
	width: 50px;
}

.textPagoSeguro{
	font-size: 11px;
    width: 104px;
    color: #828399;
    font-family: 'AvenirRegular';
    margin-top: 5px;
}

.formaDePago{
	width: 80%;
    border: 1px solid #d4d6dd;
    border-radius: 10px;
    margin: auto;
    margin-top: 20px;
    cursor: pointer;
}

.datosTipoDePago{
	width: 100%;
	display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.formaDePagoActive{
	border: 2px solid #349cef !important;
}

.tipoOpcionYNombre{
	display: flex;
}

.circulo{
	width: 20px;
	height: 20px;
	border: 1px solid #d4d6dd;
	border-radius: 100%;
	display: inline-block;
	margin: 10px;
}

.circuloActive{
	border: 5px solid #e2045b !important;
}

.nombreOpcion{
	font-family: 'AvenirRegular';
	font-size: 18px;
	color: #3d3d3d;
	margin-top: 8px;
}

.imgTajetasDeCredito{
	width: 160px;
}

.imgPagoSpei{
	width: 100px;
}

.divAccionPago{
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 80%;
    margin: 10px;
}

.atrasPago{
	color: #222222;
    font-family: 'AvenirRegular';
    font-weight: bold;
    margin: auto;
    cursor: pointer;
}

.terminarPago{
	width: 30%;
	height: 48px;
	background: #e2045b;
	border: 0px;
	border-radius: 5px;
	padding: 10px;
	color: #fff;
	text-align: center;
	font-weight: bold;
}

.paddingTerminarPago{
	padding: 10px;
	width: 50%;
}

.elCienPorCiento{
	width: 100%;
}

.mensajePagoFinaliza{
	color: #e2045b;
	font-family: 'AvenirRegular';
	text-align: center;
	font-weight: bold;
}

.parrafoFinal{
	color: #818181;
	font-family: 'AvenirRegular';
	text-align: center;
}

.hrFinal{
	background: #cdced7;
	width: 100%;
	height: 1px;
}

.opcionesFinales{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.elegirFinal{
	width: 30%;
	display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #d4d6dd;
    border-radius: 10px;
    cursor: pointer;
}

.parrafoNecesitas{
	color: #000;
	font-weight: bold;
	text-align: center;
	font-family: 'AvenirRegular';
}

.negro{
	color: #000 !important;
}

@media(max-width: 998px){
	.datosTipoDePago{
	    flex-direction: column;
	}
	.inputConBoton{
		/*width: auto !important;*/
		width: 77% !important;
	    margin: 0;
	    margin-bottom: 20px;
	}
	.input-group2 {
	    width: auto !important;
	}
	.botonDescuento{
		margin-left: -37px;
	}
	.tituloIndicacion{
		width: auto;
	}
	.paddingTerminarPago{
		width: 100%;
	}
	.opcionesFinales{
		flex-direction: column;
	}
	.elegirFinal{
		width: 100%;
	}
}
