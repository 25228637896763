.labelLegalizar{
	color: #222222;
	font-weight: bold;
	font-family: "Avenir Familia", Sans-serif;
}

.widthParaTres{
	width: 33.3%;
}

.circulo30{
	width: 30px;
}