.tituloHorarios{
	color: #414141;
	font-size: 8px;
	font-family: "Avenir Familia", Sans-serif;
	width: 100%;
	text-align: center;
	margin-bottom: 5px;
}

.horario{
	width: 100%;
    padding: 5px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-family: "Avenir Familia", Sans-serif;
    margin-bottom: 5px;
    font-size: 14px;
}

.hay{
	background: #1a8fed;
}

.sin{
	background: #bcbec9;
}