body{
	background: #fff;
	font-family: AvenirRegular, sans-serif !important;
}

.container{
	max-width: 1200px !important;
}

.containerHome{
	width: 100%;
	height: 100vh;
}

.shape{
	fill: #fff;
    border-bottom: 0px solid #fff;
}
.container-shape{
	bottom: -8%;
    z-index: 1;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
}
.seccion1{
	background: #00003c;
	width: 100%;

	background-color: transparent;
    background-image: radial-gradient(at center center, #282a71 2%, #00003c 57%);
    position: relative;
    margin-top: 71px;
}

.seccion2{
	/*margin-top: 5%; */
	padding-bottom: 90px;
	padding-top: 90px;
}

.seccion4{
	background: #00003c;
	padding-bottom: 90px;
    padding-top: 90px;
}

.seccion5{
	padding-bottom: 90px;
    padding-top: 90px;
	background: #f5f5fc;
}

.seccion6{
	/*width: 100%;
	background: linear-gradient(to bottom, white 0%, white 30%, #00003c 30%, #00003c 30%);
	margin-top: 150px;
	padding-top: 15px; 
	display: flex;
    flex-direction: row;
    justify-content: flex-end;*/
        width: 100%;
    background: linear-gradient(to bottom, #f5f5fc 0%, #f5f5fc 30%, #00003c 30%, #00003c 30%);
    /*padding-top: 155px;*/
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.seccion7{
	padding-bottom: 90px;
    padding-top: 90px;
}

.tituloSeccion1{
	color: #FFFFFF;
    font-size: 55px;
    font-weight: 800;
    line-height: 1.2em;
    margin-top: 25%;
    font-family: "AvenirBlack";
    width: 130%;
}

.tituloSeccion2{
	color: #7f85ef;
    font-size: 20px;
    font-weight: 700;
	font-family: "AvenirRegular";
}
.cls-1{fill:#f5f5fc;}
.simbolo1{
	    position: absolute;
    width: 50%;
    top: -2%;
    right: -2%;
    z-index: -1;
}
@media(min-width:1650px){
	.simbolo1{
	    position: absolute;
	    width: 50%;
	    top: -2%;
	    right: -7%;
	    z-index: -1;
	}
}
.imgHomeSeccion1{
	position: relative;
}
@media(min-width:1570px)
{
	.imgHomeSeccion1{
		width: 450px;
	    /*right: -17%;*/
	}
}
.preguntaSeccion2{
	color: #e1195d;
    font-size: 4.1rem;
    font-weight: 800;
    line-height: 1.2em;
    font-family: 'AvenirBlack';
    margin-bottom: 2rem;
}

.parrafoSeccion2{
	color: #3a3a3a;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.4em;
    font-family: "AvenirRegular";
}

.letrasAzuleSeccion2{
	color: #00003c;
    font-size: 1.32rem;
    font-weight: bold;
    font-family: "AvenirBlack";
}

.separadorSeccion{
	width: 15%;
	background: #e1195d;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-top: 25px;
}

.preguntaSeccion3{
	font-size: 4rem;
    font-weight: 800;
    line-height: 1.2em;
    color: #00003c;
    text-align: center;
    margin-bottom: 50px;
    font-family: "AvenirBlack";
}

.producto{
	box-shadow: 0px 6px 40px -5px rgb(0 0 0 / 19%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 20px 0px 20px;
    padding: 0px 60px 60px 60px;
    background-color: #fff;
    border-radius: 100px 100px 100px 100px;
    display: inline-block;
    width: 40%;
    height: 400px;
    margin-top: 50px;
}

.imgProducto{
	width: 120px;
	display: block;
	margin: auto;
	margin-top: -50px; 
}

.tituloProducto{
    font-size: 28px;
    font-weight: bold;
    line-height: 1.2em;
    text-align: center;
    color: #00003c;
    margin: 1.25rem 0;
    font-family: AvenirRegular, sans-serif !important;
}

.parrafoProducto{
	color: #3A3A3A;
    text-align: center;
    margin-bottom: 0;
    height: 140px;

}

.botonLegalizarlo{
	background: #E1195D;
    border-color: #E1195D;
    display: block;
    margin: auto;

    border-radius: 7px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: bold;
}

.centrar{
	text-align: center;
}

.preguntaSeccion4{
    color: #FFFFFF;
    font-size: 3rem;
    font-weight: 800;
    line-height: 1.2em;
    font-family: "AvenirBlack";
}

.porques{
    display: inline-flex;
    margin: 20px;
    margin-top: 50px;
    width: 300px;
    min-height: 200px;
    flex-flow: column;
}

.imgPorque{
	width: 120px;
	display: block;
	margin: auto;
}

.tituloPorque{
	color: #FFFFFF;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.2em;
    margin: 1rem 0;
}

.parrafoPorque{
	color: #FFFFFF;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2em;
    opacity: 0.5;
}

.preguntaSeccion5{
	color: #00003c;
    font-size: 50px;
    font-weight: 800;
    line-height: 1.2em;
    font-family: 'AvenirBlack';
}

.preguntaSeccion6{
	color: #e1195d;
    font-size: 24px;
    font-weight: bold;
    margin-top: 50%;
}

.tituloSeccion6{
	color: #FFFFFF;
    font-size: 44px;
    font-weight: 800;
    line-height: 1.2em;
    font-family: 'AvenirBlack';
}
.cls-2{fill:#e2005b;}
.symbol2{
	position: absolute;

    width: 20%;
    top: 30%;
}
@media(min-width:1650px)
{
	.symbol2{
		position: absolute;
	    left: -25%;
	    width: 20%;
	    top: 30%;
	}
}
.parrafoSeccion6{

    	color: #fff;
	font-size: 18px;
	width: 75%;
}

.hombreEmpresario{
	width: 90%;
    /* position: absolute; */
    margin-left: -150px;
    z-index: 1;
    position: inherit;

}
.ue{
	/*margin-top: -60px; */
    position: absolute;
    top: 28%;

    width: 100%;
    z-index: 0;
}
@media(min-width:1650px){
	.ue{
		/*margin-top: -60px; */
	    position: absolute;
	    top: 25.5%;
	    right: -20%;
	    width: 140%;
	    z-index: 0;
	}
	.hombreEmpresario{
		    width: 100%;
	    /* position: absolute; */
	    /* margin-left: -572px; */
	    z-index: 1;
	    position: inherit;
	    right: -25%;
	}
}

.botonIzquierda{
	width: 30%;
    display: inline-block;
}

.tituloSeccion7{
	color: #e1195d;
    font-size: 50px;
    font-weight: 800;
    line-height: 1.2em;
    text-align: center;
    font-family: 'AvenirBlack';
}

.paddingTop{
	padding-top: 10%;
}

.carousel .control-dots .dot {
    background: #e1195d !important;
}

.imgHomeSeccion2{
	/*margin-left: -13%;*/
}

.seccion3{
	padding-bottom: 90px;
    padding-top: 90px;
}

.carousel-status{
	display: none;
}

.ocultarCarruselEscritorio{
	display: none;
}

@media(max-width: 998px){
	.ocultarCarruselEscritorio{
		display: block;
	}
	.seccion1{
		border-bottom-right-radius: 0%;
		border-bottom-left-radius: 0%; 
	}
	.seccion2 {
	    padding-bottom: 60px;
	    padding-top: 60px;
	}
	.seccion3 {
	    padding-bottom: 60px;
	    padding-top: 60px;
	}
	.seccion4 {
	    padding-bottom: 60px;
	    padding-top: 60px;
	}
	.seccion5 {
	    padding-bottom: 60px;
	    padding-top: 60px;
	}
	.tituloSeccion1{
		font-size: 32px;
		width: auto;
		margin-top: 0;
	}
	.tituloSeccion2{
		font-size: 18px; 
	}
	.imgHomeSeccion1{
		border-bottom-right-radius: 0%;
		width: 70%;
		margin: auto;
	}
	.preguntaSeccion2{
		font-size: 32px;
		line-height: 1.2em;
		margin-bottom: 16px;
	}
	.parrafoSeccion2 {
	    font-size: 18px;
	}
	.letrasAzuleSeccion2{
		font-size: 18px;
		margin-bottom: 0px;
	}
	.imgHomeSeccion2{
		width: 100%;
		margin: auto;
		display: block;
	}
	.producto{
		width: 100%;
	    height: auto;
	    margin: auto;
	    margin-top: 140px;
	}
	.tituloProducto{
		font-size: 24px;
	}
	.parrafoProducto{
		height: auto;
	}
	.porques{
		display: block;
		margin: auto;
		margin-top: 20px;
		height: 300px; 
	}
	.botonIzquierda{
		width: auto;
	}
	.tituloSeccion7 {
	    font-size: 28px;
	}
	.hombreEmpresario {
	    margin-top: 20px;
	    width: 85%;
	    position: relative;
	    margin-left: auto;
	}
	.paddingBottom{
		padding-bottom: 20px; 
	}
	.preguntaSeccion6{
		margin-top: 0;
	}
	.u{
		display: none;
	}
	.seccion6{
		padding-top: 60px;
		background: #00003c;
	}
	.simbolo1{
		display: none;
	}
	.menosRelleno{
		padding-bottom: 30px; 
		padding-left: 30px;
		padding-right: 30px;
	}
	.marginInferiorProducto{
		margin-bottom: 30px;
	}
	.preguntaSeccion3{
		font-size: 32px;
		margin-bottom: 0px;
	}
	.preguntaSeccion4{
		font-size: 32px;
	}
	.imgPorque {
		width: 100px;
	}
	.preguntaSeccion5{
		font-size: 32px;
	}
	.symbol2Ocutar{
		display: none;
	}
	.ocultarCarrusel{
		display: none;
	}
}
