.footer{
	background: #6A6B7B;
	padding-top: 25px;
    padding-bottom: 25px;
}

.listaFooter{
	color: #fff;
	font-family: "Avenir Familia", Sans-serif;
	cursor: pointer;
}

.containerRedesSociales{
	display: flex;
	flex-direction: row;
}

.imgSocial{
	width: 50px;
    height: 50px;
    margin: 5px;
    cursor: pointer;
}

.h4Footer{
	color: #fff;
	font-weight: bold;
	text-align: center;
}