.seccion2Planes{
	padding: 20px;
}

.fondoSeccion1Planes{
	background: url(https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fplanes%2FfondoSeccion1Planes.png?alt=media&token=3a521b2a-1759-416f-9720-3cc12435405b);
	background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}

.eleccionPlan{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.opcionTipoPlan{
	background: #eeeeee;
	height: 50px;
	cursor: pointer;
	padding-left: 20px;
	padding-right: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.opcionTipoPlanActivo{
	background: #00003c;
}

.textoOpcionTipoPlan{
	color: #85869c;
	font-weight: bold;
	font-family: "Avenir Familia", Sans-serif;
}

.textoOpcionTipoPlanActivo{
	color: #fff;
}

.borderRedondoIzquierdo{
	border-top-left-radius: 20px; 
	border-bottom-left-radius: 20px; 
}

.borderRedondoDerecho{
	border-top-right-radius: 20px; 
	border-bottom-right-radius: 20px; 
}

.listaDePlanes{
	width: 100%;
	padding: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: flex-start;
    flex-wrap: wrap;
}

.plan{
	background: transparent;
    margin: 1.5rem;
    border-radius: 50px;
    display: inline-block;
    width: 320px;
    height: 1000px;
    box-shadow: 0px 0px 28px #cccc;
    border: 2px solid #8389ef;
}

.tituloPlan {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #00003c;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footerPlan{
	width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #00003c;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tituloPlanH4 {
    color: #fff;
    font-weight: bold;
    font-family: "Avenir Familia", Sans-serif;
    width: 100%;
    text-align: center;
    margin: auto;
}

.infoPlan {
    width: 100%;
    height: 633px;
    padding-top: 40px;
    padding-bottom: 40px;
    /*display: flex;*/
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.descripcionCortaPlan {
    color: #fff;
    width: 80%;
    font-family: "Avenir Familia", Sans-serif;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
}

.precioPlan {
    color: #fff;
    font-weight: bold;
    font-family: "Avenir Familia", Sans-serif;
    text-align: center;
}

.puntoPlan{
	width: 100%;
	display: flex;
	flex-direction: row;
	padding: 5px;
}

.checkPlan{
	color: #e1195d; 
	font-weight: bold; 
	margin-right: 10px;
}

.textoPuntoPlan{
	color: #4f4f4f;
	font-family: "Avenir Familia", Sans-serif;
	text-align: left;
}

.tituloSobrePlanes{
    color: #FFFFFF;
    font-size: 48px;
    line-height: 1.2em;
    font-family: "Avenir Familia", Sans-serif;
}

.margenesPorQues{
    margin: auto;
    margin-top: 50px;
}

@media(max-width: 998px){
    .listaDePlanes{
        padding: 0px;
    }
    .plan{
        margin: auto;
        margin-top: 80px;
        height: auto;
    }
}