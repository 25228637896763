.formPago{
	width: 100%;
	background: #f0f0f0;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;  
}

.colFormPago{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 10px;
}

.inputWidthCustom{
	width: 49%;
}

.inputWidthCustom2{
	width: 33%;
}

.inputWidthCustom3{
    width: 16.5%;
}


.botonPagar{
	width: 30%;
	height: 48px;
	background: #00003c;
	border: 0px;
	border-radius: 5px;
	padding: 10px;
	color: #fff;
	text-align: center;
	font-weight: bold;
}

.tarjetaOXXO {
    width: 512px;
    border: 1px solid grey;
    border-radius: 5px;
    text-align: center;
    display: block;
    margin: auto;
}

.ficha {
    width: 80%;
    background: #000;
    color: #fff;
    padding: 10px;
    display: block;
    margin: auto;
}

.iconoYPrecio {
    width: 80%;
    padding: 10px;
    display: block;
    margin: auto;
}

.imgOXXO {
    width: 170px;
    margin: 10px;
    display: inline-block;
    float: left;
}

.precioPago {
    display: inline-block;
    width: 50%;
    text-align: left;
}

.divReferencia {
    width: 50%;
    text-align: left;
    display: block;
    margin: auto;
}

.referencia {
    width: 80%;
    background: #f8f9fa;
    color: #000;
    padding: 10px;
    text-align: center;
    border: 2px solid grey;
    font-weight: bold;
    font-size: 24px;
}

.opps-instructions {
    padding: 32px 45px 45px;
    border-top: 1px solid #b0afb5;
    background: #f8f9fa;
    text-align: left;
    margin-top: 30px;
}

.oppsol {
    margin: 17px 0 0 16px;
    font-size: 16px;
}

.opps-footnote {
    margin-top: 22px;
    padding: 22px 20 24px;
    color: #108f30;
    text-align: center;
    border: 1px solid #108f30;
    border-radius: 4px;
    background: #ffffff;
}
.montoFicha{
	color: grey; 
	font-size: 9px;
}

.precioFicha{
	color: #000; 
	font-weight: bold; 
	font-size: 30px;
}

.textoFicha{
	color: grey; 
	font-size: 9px;
}

.textoFicha2{
	color: grey;
}