.seccion1Nosotros{
	background: #f5f5fc;
	width: 100%;
	margin-top: 71px;
	/*border-bottom-right-radius: 40%;
	border-bottom-left-radius: 40%; */
}
.shape{
	fill: #fff;
    border-bottom: 0px solid #fff;
}
.container-shape{
	bottom: -4%;
    z-index: 1;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
}
.cls-1p{fill:#388fed;}
.cls-1t{fill:#eaeaf5;}
.positionrelativetext{
	position: relative;
	z-index: 2;
}
.figure1{
	background: url('https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fdocumentos%2Felement-grid-blue.svg?alt=media&token=418fe5a7-e4dc-4f86-b41e-1f0fe862f5b8&_gl=1*1e78uz4*_ga*MTMxNDUzMjAzMy4xNjgzNzI4Njg5*_ga_CW55HF8NVT*MTY4NTQ5MTQwMi4xNi4xLjE2ODU0OTE1MDkuMC4wLjA.');
	width: 10vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: unset;
    position: absolute;
    right: 20vw;
    top: 40px;
    z-index: 1;
}
.figure4{
	background: url(https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fnosotros%2Felement-symbol-g5.svg?alt=media&token=b2b827e7-659e-4755-a1fb-82afeff8f5ce&_gl=1*x0hzg0*_ga*MTMxNDUzMjAzMy4xNjgzNzI4Njg5*_ga_CW55HF8NVT*MTY4NjUwNjM2OS4xOS4xLjE2ODY1MDY4OTcuMC4wLjA.);
    width: 43vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;
    position: absolute;
    right: 00;
    top: 0px;
    z-index: 0;
}
.seccion5Nosotros{
	background: #fff;
	width: 100%;
}

.tituloSeccion1Nosotros{
	color: #00003c;
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.2em;
    margin-top: 7.5% !important;
    width: 50%;
    margin: auto;
    text-align: center;
    font-family: 'AvenirBlack';
    padding-bottom: 2rem;
}

.parrafoSeccion1Nosotros{
	color: #00003c;
    font-size: 20px;
    width: 50%;
    margin: auto;
    margin-top: 20px;
}

.negrillas{
	font-weight: bold;
}

@media(max-width: 998px){
	.seccion1Nosotros{
		border-bottom-right-radius: 0%;
		border-bottom-left-radius: 0%; 
	}
	.tituloSeccion1Nosotros{
	    font-size: 28px;
	    width: 95%;
	}
	.parrafoSeccion1Nosotros{
		font-size: 15px; 
		width: 95%;
	}
}
