.paddingCuadro{
	padding: 20px;
}

.contenido{
	color: #222222;
	border: 1px solid #dbdce2;
	padding: 20px; 
	font-family: 'AvenirRegular';
	margin: 10px;
}

.porcetaje{
	width: 300px;
	height: 40px;
	background: #bcbec9;
	border-radius: 20px;
	display: block;
    margin: auto;
}

.porcetajeInterno{
	width: 0px;
	transition: width 0.5s;
	height: 40px;
	background: #00003c;
	border-radius: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.textoPorcentaje{
	color: #fff;
	font-family: 'AvenirRegular';
}

.preguntaDuda{
	color: #606060;
	font-family: 'AvenirRegular';
	font-weight: 0;
}

.botonesDescarga{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.botonDescarga{
	width: 100px;
	margin: 10px;
	cursor: pointer;
}

.estiloDocumento{
	max-width: 720px;
    display: block;
    margin: auto;
}

.textoNormal{
	transition: all .3S;
}

.textoGrande{
	font-size: 24px;
	color: #fff;
	font-weight: bold;
	background: blue;
}

.formaEditDoc {
    width: 80%;
    border: 1px solid #d4d6dd;
    border-radius: 10px;
    margin: auto;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}