.menuLegalizarlo{
	    background: #00003c !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
}

.menuLegalizarloResponsive{
	background: #00003c !important;
	display: none;
	padding: 10px;
}

.listaResponsive{
	background: #fff;
	height: 0;
	transition: .4s;
	/*overflow: auto;*/
	display: none;
	width: 100%;
}

.listaResponsiveAbierto{
	height: 280px;
    position: fixed;
    background: #fff;
    z-index: 99;
}

.itemsLegalizarloMenu{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.itemMenuLegalizarlo{
	display: inline-block;
	color: #fff !important;
	font-weight: bold;
	transition: .4s;
	border: 0px solid #e1195d;
	font-family: "Avenir Familia", Sans-serif;
}

.itemMenuLegalizarlo:hover{
	color: #e1195d;
	background: transparent !important;
	border-bottom-width: 5px; 
}

.imgUserMenu{
	width: 35px;
	height: 35px;
	margin: 10px;
	cursor: pointer;
}

li{
	list-style:none;
}

.imgLogin{
	width: 320px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}

.imgRegistro{
	width: 320px;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	height: 100%;
    object-fit: cover;
}

.containerImgLogin{
	padding: 0px; 
	display: inline-block;
	width: auto;
}

.alinearConImgLogin{
	display: inline-block;
	min-width: 250px;
	padding: 30px;
}

.divBotonCerrar{
	text-align: right;
	margin: 5px;
}

.xCerrar{
	color: #000;
	font-weight: bold;
	font-size: 24px;
	cursor: pointer;
	font-family: "Avenir Familia", Sans-serif;
}

.tituloLogin{
	font-size: 42px;
    font-weight: 800;
    line-height: 1em;
    color: #00003c;
    text-align: center;
    font-family: 'AvenirBlack';
}

.botonfacebook {
	width: 320px;
    color: #FFFFFF;
    background-color: #4267B2;
    border-radius: 8px 8px 8px 8px;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    margin: auto;
    font-family: "Avenir Familia", Sans-serif;
}

.iconMargen{
	margin: 10px;
}

.subTituloLogin{
	text-align: center;
    margin: auto;
    font-weight: 0;
    width: 320px;
    font-size: 18px;
    font-family: 'AvenirRegular';
    margin-top: 10px;
    margin-bottom: 10px;
}

.inputLogin{
	font-size: 16px;
    min-height: 47px;
    padding: 6px 16px;
    border: 1px solid #666;
    border-radius: 5px;
    margin: 10px;
    width: 320px;
    display: block;
    margin: auto;
    margin-bottom: 10px;
}

.botonSesionEmail {
	width: 320px;
    color: #FFFFFF;
    background-color: #e1195d;
    border: 0px;
    border-radius: 8px 8px 8px 8px;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    margin: auto;
    display: block;
    font-family: "Avenir Familia", Sans-serif;
}

.botonRecuperar{
	color: #7f85f1;
	text-align: center;
	margin: 20px;
	display: block;
	font-weight: bold;
	font-family: "Avenir Familia", Sans-serif;
}

.textoRegistro{
	text-align: center;
	margin: auto;
	display: block;
	font-family: "Avenir Familia", Sans-serif;
}

.botonRegistro{
	color: #7f85f1;
	font-family: "Avenir Familia", Sans-serif;
}

.input-group2 {
    width: 320px;
    margin: auto;
    display: flex;
}

.botonEnviar{
	background: #00003c;
    color: #fff;
    padding: 5px;
    display: inline-block;
    height: 47px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: "Avenir Familia", Sans-serif;
}

.inputConBoton{
	width: 265px !important;
	border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.botonReenviar{
	color: #00003c;
    cursor: pointer;
    font-weight: bold;
    font-family: "Avenir Familia", Sans-serif;
    text-align: left;
    margin: auto;
    width: 320px;
    display: block;
}

.inputConCodigo{
	width: 227px !important;
	border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.inputRecuperar{
	margin: auto;
	margin-top: 20px;
	margin-bottom: 20px;
}

.menuAdmin{
	width: 100%;
	background: #00003c;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 10px;
}

.listaMenuAdmin{
	width: 210px;
	height: 0px;
	padding: 10px;
	background: #00003c;
	position: absolute;
	left: 0;
	transition: .5s;
}

.menuAdminActivo{
	height: 100vh !important;
	overflow: auto;
}

.iconoMenu{
	cursor: pointer;
}

.elementoMenuAdmin{
	width: 100%;
	padding: 10px;
	color: #fff;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	font-weight: bold;
	text-decoration: none;
	margin-top: 20px; 
	border-radius: 5px;
	font-family: "Avenir Familia", Sans-serif;
}

.elementoMenuAdmin:hover{
	background: #f8c43c;
	-webkit-transition: background-color .5s linear;
    -ms-transition: background-color .5s linear;
    transition: background-color .5s linear;
    color: #fff;
}

.elementoMenuAdminInvicible{
	display: none;
}

.opcionesDeSesion{
	width: 210px;
	padding: 10px;
	background: #00000099;
	position: absolute;
	border-radius: 10px;
	margin-left: -150px;
}

.tituloOpcionesSesion{
	color: #fff;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.4em;
    font-family: "Avenir Familia", Sans-serif;
}

.emailOpciones{
	color: #fff;
	font-weight: bold;
	cursor: pointer;
	font-family: "Avenir Familia", Sans-serif;
}

.hrOpciones{
	color: #fff;
	font-weight: bold;
}

.menuOpcionesCerrado{
	display: none;
}

.marginAuto{
	margin: auto !important;
	margin-bottom: 10px !important; 
}

.iconoServicios{
	width: 25px;
}

.marginLeftMenu{
	margin-left: 40px;
}

@media(max-width: 998px){
	.menuLegalizarlo{
		display: none;
	}
	.itemMenuLegalizarlo{
		margin: 15px;
		color: #00003c !important;
	}
	.menuLegalizarloResponsive{
		display: block;
	    background: #00003c !important;
	    position: fixed;
	    top: 0;
	    left: 0;
	    z-index: 9999;
	    width: 100%;
	    height: 71px;
	}
	.listaResponsive{
		display: block;
	}
	.containerImgLogin{
		display: none;
	}
	.inputLogin{
		width: 100%;
		display: block;
		margin: auto;
		margin-bottom: 15px;
	}
	.botonSesionEmail{
		width: auto;
	}
	.subTituloLogin{
		width: auto;
		font-size: 14px;
	}
	.botonfacebook{
		width: auto;
	}
	.tituloLogin{
		font-size: 39px;
	}
	.inputConCodigo{
		width: 64% !important;
		margin: 0;
	}
}
