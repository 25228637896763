.width600{
	width: 600px;
}

.tituloAgenda{
	width: 100%;
	text-align: center;
	color: #00003c;
	font-family: 'AvenirRegular';
	font-weight: bold;
}

.selecionHoraFecha{
	color: #369def;
    font-family: 'AvenirRegular';
    text-align: center;
    margin: auto;
    display: block;
}

.puntosDisponibilidad{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.puntoDisponibilidad{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 10px;
}

.puntoAzul{
	background: #264eb2;
	width: 25px;
	height: 25px;
	border-radius: 100%;
	margin: 5px;
}

.disponibilidad{
	font-family: 'AvenirRegular';
	color:  #292929;
}

.puntoMarino{
	background: #00003c;
	width: 25px;
	height: 25px;
	border-radius: 100%;
	margin: 5px;
}

.puntoGris{
	background: #bcbec9;
	width: 25px;
	height: 25px;
	border-radius: 100%;
	margin: 5px;
}

.hayDisponibilidad{
	color: #264eb2;
	font-family: 'AvenirRegular';
}

.pocaDisponibilidad{
	color: #00003c;
	font-family: 'AvenirRegular';
}

.sinDisponibilidad{
	color: #bcbec9;
	font-family: 'AvenirRegular';
}

.mes{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	margin-bottom: 10px;
}

.flecha{
	color: #bcbec9;
	font-family: 'AvenirRegular';
	font-weight: bold;
}

.flechaActiva{
	color: #e2045b;
	font-family: 'AvenirRegular';
	font-weight: bold;
}

.textoMes{
	font-family: 'AvenirRegular';
	color:  #222222;
	font-weight: bold;
}

.diasDelMes{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.inicialSemama{
	color: #222222;
	font-weight: bold;
	font-family: 'AvenirRegular';
	width: 14.2%;
	text-align: center;
}

.dia{
	width: 14.2%;
	text-align: center;
}

.diaSeleccionado{
	border-radius: 100%;
    background: #1a8fed;
    color: #fff !important;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    padding-left: 7.5px;
    padding-right: 7.5px;
}
.listaDeHorarios{
	width: 150px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    max-height: 260px;
    overflow: auto;
}

/* width */
.listaDeHorarios::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.listaDeHorarios::-webkit-scrollbar-track {
  background: #e2045b99;
}

/* Handle */
.listaDeHorarios::-webkit-scrollbar-thumb {
  background: #e2045b;
}

/* Handle on hover */
.listaDeHorarios::-webkit-scrollbar-thumb:hover {
  background: #1a8fed;
}

.dataCita{
	color: #222222;
	font-weight: bold;
	font-family: 'AvenirRegular';
}
.pendiente{
	color: #bcbec9;
}

.citado{
	color: #e2045b;
}

.horarioSeleccionado{
	background: #e2045b !important;
}

.noAgendar{
	background: #bcbec9 !important;
}

.datosFinalesCita{
	display: flex;
    margin: auto;
    border: 1px solid #484873;
    border-radius: 20px;
    text-align: center;
    padding: 1.5%;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textoCita{
	color: #00003c;
	font-weight: bold;
	font-family: 'AvenirRegular';
	text-align: center;
}

.linkDocs{
	color: #1a8fed !important;
	text-decoration: underline #1a8fed;
}
