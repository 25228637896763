.containerPreguntasYRespuestas{
	width: 80%;
	padding-top: 10% !important;
    margin: auto;
}

.tituloPreguntasYRespuestas{
	color: #00003c;
    font-size: 52px;
    font-weight: 800;
    line-height: 1.2em;
    margin: auto;
    text-align: center;
        font-family: 'AvenirBlack';
}

.preguntas{
	margin-top: 20px; 
}

.pregunta{
	width: 80%;
    margin: auto;
    border: 0px solid #dedfe7;
    border-bottom-width: 1px;
    border-top-width: 1px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.tituloPregunta{
	color: #00003c;
    font-weight: bold;
    cursor: pointer;
    width: 90%;
    margin: auto;
        font-family: 'AvenirBlack';
}

.masOMenosCerrar{
	color: #e2045b;
	    font-family: 'AvenirBlack';
}

.masOMenosAbierto{
	color: #00003d;
	    font-family: 'AvenirBlack';
}

.respuesta{
	color: #646b6f;
	height: 0px;
	overflow: auto;
	transition: height .5s;
	font-family: 'AvenirRegular';
}

.respuestaHeight{
	height: 150px;
	padding: 5%;
}

.colorGris{
	color: #686868 !important;
}

@media(max-width: 998px){
	.tituloPreguntasYRespuestas {
	    font-size: 30px;
	}
	.pregunta{
		width: 100%;
	}
}
