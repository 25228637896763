.containerComoFunciona{
	width: 80%;
    border-radius: 50px;
    background: #fff;
    box-shadow: 1px 1px 84px -18px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 1px 1px 84px -18px rgb(0 0 0 / 75%);
    -moz-box-shadow: 1px 1px 84px -18px rgba(0,0,0,0.75);
    text-align: center;
    padding-top: 5%;
    padding-bottom: 5%;
    margin: auto;
    margin-top: 80px;
    z-index: 2;
    position: relative;
}

.tituloComoFunciona{
	color: #00003c;
    font-size: 52px;
    font-weight: bold;
    line-height: 1.2em;
    margin: auto;
    text-align: center;
    font-family: "Avenir Familia", Sans-serif;
}

.pasosComoFunciona{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	flex-wrap: wrap;
	font-family: "Avenir Familia", Sans-serif;
}

.imgEInfoComoFunciona{
	text-align: center;
	margin: 10px;
}

.parrafoComoFunciona{
	text-align: center;
	font-weight: bold;
	color: #00003c;
	margin-top: 10px;
}

.flechaComoFunciona{
	width: 30px;
    height: 60px;
    margin-top: 40px;
}

@media(max-width: 998px){
	.tituloComoFunciona{
		font-size: 28px;
	}
	.containerComoFunciona{
		width: auto;
	}
	.pasosComoFunciona{
		flex-direction: column;
	}
	.flechaComoFunciona {
	    transform: rotate(90deg);
	    margin: auto;
	}
}
