.modalFondo{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #00000099;
	z-index: 5;
	overflow: auto;
}

.contenidoModal{
	position: relative;
	background: #fff;
	border-radius: 20px;
	z-index: 10;
	min-width: 320px;
	width: fit-content;
	max-width: 80%;
}

@media(max-width: 998px){
	.contenidoModal{
		margin-top: 60%;
	}
}