.containerAbogados{
	width: 100%;
	height: 100vh;
}

.seccion1Abogados{
	background: #f5f5fc;
	width: 100%;
	/*border-bottom-right-radius: 40%;
	border-bottom-left-radius: 40%; 
	padding-bottom: 10%;*/

    margin-top: 71px;
    min-height: 45vh;

    background-image: url(https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fservicios%2F2ewgYiDyd3W4NnBCnaCx%2Felement-symbol-m3.svg?alt=media&token=7e51b328-06e3-4261-ba55-64b139298d34&_gl=1*1qlwb6n*_ga*MTMxNDUzMjAzMy4xNjgzNzI4Njg5*_ga_CW55HF8NVT*MTY4NTkyNzY2Ny4xOC4xLjE2ODU5Mjc3MjMuMC4wLjA.);
    background-repeat: no-repeat;
    background-position: right top;
        background-size: 53%;
            overflow: hidden;

}
.seccion4Abogados{
	background: #f5f5fc;
	background-image: url(https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fservicios%2F2ewgYiDyd3W4NnBCnaCx%2Felement-symbol-g4.svg?alt=media&token=b824c005-fa2d-447c-b85d-ea031351452b&_gl=1*1nge8nr*_ga*MTMxNDUzMjAzMy4xNjgzNzI4Njg5*_ga_CW55HF8NVT*MTY4NTkyNzY2Ny4xOC4xLjE2ODU5Mjk0NTIuMC4wLjA.);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 22%;
}
.background-f5f5fc{
	background-color:#f5f5fc;
}
.tituloSeccion1Abogados{
	color: #00003c;
    font-size: 49px;
    font-weight: 800;
    line-height: 1.2em;
    font-family: "Avenir Familia", Sans-serif;
    /*margin-top: 25%;*/
}

.parrafoSeccion1Abogados {
    color: #222222;
    font-size: 22px;
    margin: 0;
}
.container-img{
	min-height: 65vh;
    padding-top: 100px;
    margin-top: 100px;
}
.imgAbogadosSeccion1{
	    display: block;
    margin: auto;
    /* margin-top: 10%; */
    position: relative;
    bottom: -7%;
    z-index: 0;
    width: 100%;
}
@media(max-width:998px)
{
	.imgAbogadosSeccion1{
	    bottom: -21%;
	}
}
.tituloSeccion2Abogados{
    color: #00003c;
    margin: 10px;
    font-size: 36px;
    font-weight: bold;
    font-family: "Avenir Familia", Sans-serif;
}

.tituloServicio{
	width: 100%;
    height: 120px;
    background: #00003c;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    display: flex;
    flex-direction: row;
	justify-content: center;
}

.imgServicio{
	border-top-left-radius: 50px;
	border-top-right-radius: 50px;
	position: absolute;
	width: 100%;
	height: 120px;
	object-fit: cover;
	z-index: -1;
}

.seccion1ElServicio{
    background: url(https://firebasestorage.googleapis.com/v0/b/legalizarlo.appspot.com/o/assets%2Fcore%2Fabogado%2Ffdo-asesoria-juridica.webp?alt=media&token=a97e8943-d3d8-436c-a910-4cd0112272f7);
    background-size: cover;
    background-position: center;
    width: 100%;
    border-bottom-right-radius: 15%;
    border-bottom-left-radius: 15%;
    text-align: center;
    margin-top: 71px;
}

.transparenciaNada {
    /* background: rgba(1, 2, 54, 0.8); */
    width: 100%;
    height: 100%;
    /*border-bottom-right-radius: 15%;
    border-bottom-left-radius: 15%;
    padding-top: 7.5%;
    padding-bottom: 7.5%;*/
}

.flexibilidad{
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.agerdarCita{
	background: #f6f6ed;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 100px;
    width: 500px;
}

.lineaAgendar{
	background: #7f85ef;
	width: 65px;
	height: 10px;
}

.parrafoAgendar{
	color: #00003c;
    font-size: 16px;
    font-weight: bold;
    font-family: "Avenir Familia", Sans-serif;
}

@media(max-width: 998px){
	.seccion1Abogados{
		border-bottom-right-radius: 0%;
		border-bottom-left-radius: 0%; 
		padding-bottom: 0;
	}
	.tituloSeccion1Abogados{
	    font-size: 28px;
	}
	.parrafoSeccion1Abogados{
		font-size: 15px; 
	}
	.imgAbogadosSeccion1{
		width: 100%;
	}
	.agerdarCita{
		width: 100%;
	    margin: auto;
	    margin-top: 20px;
	    margin-bottom: 20px;
	    flex-direction: column;
	}
	.elParrafoAgendar{
		margin-bottom: 20px;
	}
    .imgServicio{
        width: 100%;
    }
}
